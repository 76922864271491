export const colors = {
    green: "hsla(193, 13%, 29%, 1)",
}

export const transitions = {
    main: "all ease 0.3s",
}

/**
 * @param {number} maxWidth - @media (min-width: ${maxWidth}px);
 */
const customMediaQuery = minWidth => `@media (min-width: ${minWidth}px)`

export const media = {
    custom: customMediaQuery,
    xl: customMediaQuery(1200),
    lg: customMediaQuery(992),
    md: customMediaQuery(768),
    sm: customMediaQuery(576),
}

export const CATEGORIES = Object.freeze({
    ARCH: "Архитектурные",
    INTER: "Дизайн интерьеров",
    CONCEPT: "Концептуальные",
})

export const ROUTES = Object.freeze({
    HOME: "/",
    NEWS: "/news",
    WORKS: "/works",
    ARCH: "/works?arch",
    INTER: "/works?inter",
    CONTACTS: "/contacts",
    CONCEPT: "/works?concept",
})

export const emailRegex = new RegExp(
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)
