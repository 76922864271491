import React from "react"
import * as PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"

import Arrow from "../../SVG/Arrow"
import { LinkWrapper, CustomLink, A, Button } from "./styled"

const Link = ({ to, children, color, textTransform, variant, ...rest }) => {
    const isOuterLink =
        to.includes("http") || to.includes("mailto:") || to.includes("tel:")

    // Varinat === 'moreButton'
    if (variant === "moreButton") {
        return (
            <Button type="button">
                {isOuterLink ? (
                    <a
                        href={to}
                        target="_blank"
                        rel="noopener noreferrer"
                        {...rest}
                    >
                        {children} <Arrow />
                    </a>
                ) : (
                    <GatsbyLink to={to} {...rest}>
                        {children} <Arrow />
                    </GatsbyLink>
                )}
            </Button>
        )
    }

    // Varinat === 'link'
    return (
        <LinkWrapper color={color} textTransform={textTransform}>
            {isOuterLink ? (
                <A
                    href={to}
                    target="_blank"
                    rel="noopener noreferrer"
                    {...rest}
                >
                    {children}
                </A>
            ) : (
                <CustomLink to={to} {...rest}>
                    {children}
                </CustomLink>
            )}
        </LinkWrapper>
    )
}

Link.defaultProps = {
    textTransform: `uppercase`,
    variant: `link`,
}

Link.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    color: PropTypes.string,
    variant: PropTypes.oneOf(["link", "moreButton"]),
    textTransform: PropTypes.oneOf([
        "uppercase",
        "lowercase",
        "inherit",
        "initial",
        "capitalize",
        "unset",
    ]),
}

export default Link
