import React from "react"

function Icon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0.636 81 14.728"
            {...props}
        >
            <path
                fill="#415054"
                d="M80.707 8.707a1 1 0 000-1.414L74.343.929a1 1 0 10-1.414 1.414L78.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 9h80V7H0v2z"
            ></path>
        </svg>
    )
}

export default Icon
