import React from "react"
import PropTypes from "prop-types"

import { colors } from "../../../helpers/variables"

const Text = props => {
    const { variant, color, children, style, align, weight, className } = props
    const Component = variantMapping[variant]

    return (
        <Component
            className={className}
            style={{
                color: color,
                textAlign: align,
                fontWeight: weight === "bold" ? 700 : "inherit",
                ...style,
            }}
        >
            {children}
        </Component>
    )
}

const variantMapping = {
    // font-size: 64px;
    h1: "h1",

    // font-size: 54px;
    h2: "h2",

    // font-size: 48px;
    h3: "h3",

    // font-size: 24px;
    h4: "h4",

    // font-size: 20px;
    h5: "h5",

    // font-size: 18px;
    h6: "h6",

    // font-size: 16px;
    p: "p",
    span: "span",
}

Text.defaultProps = {
    style: {},
    color: colors.green,
    className: "",
    weight: "reglar",
    align: "left",
}

Text.propTypes = {
    style: PropTypes.object,
    color: PropTypes.string,
    className: PropTypes.string,
    align: PropTypes.oneOf(["left", "center", "right"]),
    weight: PropTypes.oneOf(["reglar", "bold"]).isRequired,
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p", "span"])
        .isRequired,
}

export default Text
