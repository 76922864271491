import styled from "styled-components"

export const Ul = styled.ul`
    /* Reset list */
    padding: 0;
    margin: 0;
    list-style: none;

    li::before {
        display: none;
    }

    li + li {
        margin-top: 0;
    }
`

export const PureButton = styled.button`
    /* Reset */
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    outline: 0; /* We disable the focus ring for mouse, touch and keyboard users. */
    font: inherit;
    overflow: visible;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    line-height: normal;
    text-decoration: none;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;

    ::-moz-focus-inner {
        border-style: none; /* Remove Firefox dotted outline. */
    }
    :disabled {
        pointer-events: none; /* Disable link interactions */
        cursor: default;
    }
`
