import styled from "styled-components"

import Link from "../shared/Link"
import { Ul } from "../shared/styled"
import { transitions, media, colors } from "../../helpers/variables"

export const Header = styled.header`
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 106px;
    position: fixed;
    transition: ${transitions.main};
    background-color: hsla(230, 10%, 88%, 1);
    top: ${props => (props.isVisible ? 0 : "-106px")};
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.02);
`

export const Nav = styled.nav`
    width: 100%;
    display: flex;
    padding: 35px 0;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`

export const UL = styled(Ul)`
    display: none;

    ${media.lg} {
        display: inline-flex;
        justify-content: space-between;
        max-width: 470px;
        width: 100%;
    }

    li {
        padding: 0;
    }

    ${props =>
        props.isVisible &&
        `
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: hsla(230, 10%, 88%, 1);
        position: fixed;
        height: 100vh;
        width: 100%;
        z-index: 2;
        top: 0;
        left: 0;

        a {
            font-size: 24px;
            margin: 10px 0;
        }
    `}
`

export const Burger = styled.div`
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    display: flex;
    height: 20px;
    width: 30px;
    z-index: 3;

    ${media.lg} {
        display: none;
    }

    div {
        width: 100%;
        height: 2px;
        background-color: ${colors.green};
    }

    ${props =>
        props.isActive &&
        `
        div {
            :nth-of-type(1) {
                transform: rotate(45deg);
                transform-origin: left;
                top: -3px;
                position: relative;
            }
            :nth-of-type(2) {
                display: none;
            }
            :nth-of-type(3) {
                transform: rotate(-45deg);
                transform-origin: left;
            }
        }
    `}
`

export const LogoLink = styled(Link)`
    font-size: 24px;
    z-index: 3;
`

export const InstWrapper = styled.li`
    text-transform: uppercase;

    svg {
        margin-bottom: 8px;
    }

    ${media.lg} {
        display: none;
    }
`
