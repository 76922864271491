import styled from "styled-components"
import { Link } from "gatsby"

import { PureButton } from "../styled"
import { transitions, media } from "../../../helpers/variables"

// LINK
export const CustomLink = styled(Link)`
    text-decoration: none;
    letter-spacing: 1px;
    position: relative;
    font-size: 20px;
    display: block;

    ${media.lg} {
        :hover {
            text-decoration: none;
        }
    }
`

export const A = styled.a`
    text-decoration: none;
    letter-spacing: 1px;
    position: relative;
    font-size: 20px;
    display: block;

    ${media.lg} {
        :hover {
            text-decoration: none;
        }
    }
`

export const LinkWrapper = styled.div`
    width: fit-content;
    display: inline-block;

    ${CustomLink}, ${A} {
        text-decoration: none;
        text-transform: ${props => props.textTransform};
        color: ${props => (props.color ? props.color : "white")};

        ${media.lg} {
            :before,
            :after {
                content: "";
                border-bottom: solid 1px
                    ${props => (props.color ? props.color : "white")};
                position: absolute;
                bottom: 0;
                width: 0;
            }

            :before {
                left: 0;
            }
            :after {
                right: 0;
            }

            :hover:before,
            :hover:after {
                width: 50%;
            }

            :before,
            :after {
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }
        }
    }
`

// MORE BUTTON
export const Button = styled(PureButton)`
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: transparent;
    transition: ${transitions.main};
    color: hsla(195, 15%, 30%, 0.95);

    ${media.sm} {
        font-size: 20px;
    }

    svg {
        transition: ${transitions.main};
        position: relative;
        height: 10px;
        left: 10px;
        top: -2px;

        ${media.sm} {
            height: 15px;
        }
    }

    a {
        color: inherit;

        ${media.lg} {
            :hover {
                text-decoration: none;
            }
        }
    }

    ${media.lg} {
        :hover {
            svg {
                left: 20px;
            }
        }
    }
`
