import { useToggle, useLockBodyScroll, useMedia } from "react-use"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import Link from "../shared/Link"
import InstagramIcon from "../SVG/Instagram"
import { colors, ROUTES } from "../../helpers/variables"
import { Header, Nav, UL, LogoLink, Burger, InstWrapper } from "./styled"

const HeaderComponen = ({ siteAuthor }) => {
    const [on, toggle] = useToggle(false)
    useLockBodyScroll(on)

    const isWide = useMedia("(min-width: 992px)")
    useEffect(() => {
        if (isWide && on) toggle()
    }, [isWide, on, toggle])

    const [state, setState] = useState({
        prevScroll: 0,
        isVisible: true,
    })

    const handleScroll = () => {
        const { prevScroll } = state

        const currentScrollPos = window.pageYOffset
        const visible = prevScroll > currentScrollPos

        setState({
            prevScroll: currentScrollPos,
            isVisible: currentScrollPos < 10 ? true : visible,
        })
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

        return function cleanup() {
            window.removeEventListener("scroll", handleScroll)
        }
    })

    return (
        <Header isVisible={state.isVisible}>
            <div className="container">
                <Nav>
                    <LogoLink to={ROUTES.HOME} color={colors.green}>
                        {siteAuthor}
                    </LogoLink>

                    <Burger role="button" isActive={on} onClick={toggle}>
                        <div />
                        <div />
                        <div />
                    </Burger>

                    <UL isVisible={on}>
                        <li>
                            <Link to={ROUTES.WORKS} color={colors.green}>
                                Проекты
                            </Link>
                        </li>
                    
    {/*                 <li>
                            <Link to={ROUTES.NEWS} color={colors.green}>
                                О студии
                            </Link>
                        </li>
    */}

                        <li>
                            <Link to={ROUTES.CONTACTS} color={colors.green}>
                                Контакты
                            </Link>
                        </li>
                        <InstWrapper>
                            <InstagramIcon
                                color={colors.green}
                                style={{
                                    marginRight: 10,
                                    marginBottom: 4,
                                    height: 20,
                                    width: 20,
                                }}
                            />
                            <Link
                                color={colors.green}
                                textTransform="inherit"
                                to="http://instagram.com/sd_zilyaneva"
                            >
                                Instagram
                            </Link>
                        </InstWrapper>
                    </UL>
                </Nav>
            </div>
        </Header>
    )
}

Header.propTypes = {
    siteAuthor: PropTypes.string,
}

Header.defaultProps = {
    siteAuthor: ``,
}

export default HeaderComponen
